<template>
  <div class="about">
    <div class="site-section bg-light">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" style="margin-top: 60px;">
            <h2 class="section-title mb-3 text-normal-blue">What We Do</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="container">
            <div class="col-lg-12">
              <p class="mb-4 text-black">We are a management and business data and digital solutions consultants. We help established company owners and enterprise business leaders with strategy and software engineering for analytics driven business system processes.</p>

              <p class="mb-4 text-black">Our clients hire us to increase their enterprise value rapidly and turn over their companies to run using efficient, effective and smarter automated processes using our management services and digital process and workflow solutions.</p>

              <p class="mb-4 text-black">We provide turn-key resilient digital platform solutions, management and technology advisory consulting services to our client team with experienced management and technical staff working side by side as an extended team member.</p>

              <p class="mb-4 text-black">Our management and IT consultants are experts in assisting and guiding our clients with </p>

              <ul class="ul-check mb-5 list-unstyled success text-black">
                <li>Company Turnover </li>
                <li>Lean process optimization</li>
                <li>Effective management</li> 
                <li>Custom business process automation</li>
                <li>Design and development of no-code, low-code software applications.</li>
                <li>Data collection</li>
                <li>Data integrate and Information contextualization for actionable insights</li>
                <li>Distributed data processing on a multi-cloud or hybrid data centers. </li>
              </ul>

              <p class="mb-4 text-black">We help our client businesses to gain a competitive edge, with multi cloud data platforms and software tools.  
              With our proprietary and partner Digital platform solutions and custom system integration and  managed services. We offer our clients to provide exceptional customer service and seamless human and machine interactions experience that produce new outcomes and market expansion for their business growth.</p>
              <p class="mb-4 text-black">Clikry Consultants are key partners for visionaries and transforamtive leaders to build strategies and tactically execute their  business growth and value creation goals in four steps:</p>
            </div>
          </div>
        </div>
        <div class="feature-big">
          <div class="container">
            <div class="row mb-5 site-section">
              <div class="col-lg-7" data-aos="fade-right">
                <img src="images/Engagement1.svg" alt="Image" class="img-fluid">
              </div>
              <div class="col-lg-5 pl-lg-5 ml-auto mt-md-5">
                <h2 class="text-black text-normal-blue">Engagement</h2>
                <p class="mb-4 text-black">We assess your current business processes to identify areas of improvement to boost agility and operational performance so you can maximize resources while supporting growth.</p>
              </div>
            </div>
            <div class="mt-5 row mb-5 site-section ">
              <div class="col-lg-7 order-1 order-lg-2" data-aos="fade-left">
                <img src="images/undraw_building_blocks_n0nc.svg" alt="Image" class="img-fluid">
              </div>
              <div class="col-lg-5 pr-lg-5 mr-auto mt-5 order-2 order-lg-1">
                <h2 class="text-black text-normal-blue">Enablement</h2>
                <p class="mb-4 text-black">We make it easy for business to use the technology without any capital expenditure, we provide Flexible infrastructure as an operating expense and grow on demand which helps our clientele deliver experiences that streamline their customers experience and employee’s decision-making process.</p>
              </div>
            </div>
            <div class="row mb-5 site-section">
              <div class="col-lg-7" data-aos="fade-right">
                <img src="images/Empowerment1.svg" alt="Image" class="img-fluid">
              </div>
              <div class="col-lg-5 pl-lg-5 ml-auto mt-md-5">
                <h2 class="text-black text-normal-blue">Empowerment</h2>
                <p class="mb-4 text-black">We help measure your business operations and growth with important data, anything that can be measured can be easily managed, we help identify key areas and performance indicators that create new growth and enterprise value. Better data analytics practices create superior business outcomes. We provide scorecards and digital dashboards to monitor while running your business and let you harness key data from many sources that were untapped before.</p>
              </div>
            </div>
            <div class="mt-5 row mb-5 site-section ">
              <div class="col-lg-7 order-1 order-lg-2" data-aos="fade-left">
                <img src="images/Execution1.svg" alt="Image" class="img-fluid">
              </div>
              <div class="col-lg-5 pr-lg-5 mr-auto mt-5 order-2 order-lg-1">
                <h2 class="text-black text-normal-blue">Execution</h2>
                <p class="mb-4 text-black">We help protect your data and transform your business from a reactive to proactive systems with modern process design and automation. Introducing best practices about data security approach safeguards your future-ready information technology.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Footer
  }
};
</script>